import React, { useEffect, useRef, useState } from 'react';
import ListQuotesComponent from './style';
import DropdownCustom from 'src/components/dropdownCustom';
import { Input, Popover, Table } from 'antd';
import icons from 'src/assets/icon';
import {
    listSearch,
    listSort,
    showColumOnPage,
} from 'src/const/enum';
import ModalComponent from 'src/components/modalCustom';
import VersionModal from './VersionModal';
import NotFound from '../../../components/NotFoundData';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listQuoteSelector } from 'src/redux/selectors/listQuoteSelecter';
import { getListAction } from 'src/redux/actions/getListAction';
import moment from 'moment';

const ViewTableComponent = () => {
    const [page, setPage] = useState<number>(10);
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();
    const listQuote = useSelector(listQuoteSelector);
    const [dataList, setDataList] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const [debouncedValue, setDebouncedValue] = useState<string>('');
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const callListQuote = () => {
        const searchByQuoteRef = `?quoteRef=${debouncedValue}`;
        const searchClientName = `?clientName=${debouncedValue}`;

        if (selectedValue === null || selectedValue === 'Quote Ref') {
            dispatch(getListAction(searchByQuoteRef));
            setDataList(listQuote);
        }
        if (selectedValue === 'Client Name') {
            dispatch(getListAction(searchClientName));
            setDataList(listQuote);
        }
    };

    useEffect(() => {
        setDataList(listQuote);
    }, [listQuote]);

    useEffect(() => {
        callListQuote();
    }, [selectedValue, debouncedValue,]);

    const handleDataSort = (value: any) =>{
        console.log(value)
        const sortedList = [...dataList].sort((a:any, b:any) => {
            const dateA = value === 'Updated date'
                ? new Date(a.updatedDate || '').getTime()
                : new Date(a.createdDate || '').getTime();
            const dateB = value === 'Created date'
                ? new Date(b.updatedDate || '').getTime()
                : new Date(b.createdDate || '').getTime();
            return dateB - dateA;
        });
        console.log(sortedList)
        setDataList(sortedList)
    } 

    

    const showModal = (event: any) => {
        event.stopPropagation();
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        setSearchValue(e.target.value);
        debounceTimeout.current = setTimeout(() => {
            setDebouncedValue(value);
        }, 1000);
    };

    const columns = [
        {
            title: 'Quote Ref',
            dataIndex: 'quoteRef',
            key: 'quoteRef',
            className: 'quoteRefColumn',
        },
        {
            title: 'Quote Title',
            dataIndex: 'quoteTitle',
            key: 'quoteTitle',
            className: 'quoteTitleColumn',
        },
        {
            title: 'Departure date',
            dataIndex: 'departureDate',
            key: 'departureDate',
            className: 'departureDateColumn',
            render: (text: string) => (
                <div>{moment(text).format('MMM D YYYY')}</div>
            ),
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            className: 'clientNameColumn',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text: string, record: any) => (
                <div className="viewGroupsActions">
                    <Popover content={'Copy quote link'}>
                        <img
                            alt=""
                            className="actionIcon"
                            src={icons.shareLink}
                        />
                    </Popover>
                    <img alt="" className="actionIcon" src={icons.download} />
                    <img
                        alt=""
                        className="actionIcon"
                        src={icons.clockIcon}
                        onClick={showModal}
                    />
                </div>
            ),
        },
    ];

    const handleChangePagination = (data: string) => {
        setPage(Number(data));
    };
    const handleRowClick = (item: any) => {
        history.push('/quoteDetail', 'Preview mode');
    };

    const handleDataChange = (value: string) => {
        setSelectedValue(value);
    };

    

    return (
        <ListQuotesComponent>
            <div className="viewSreach">
                <div className="searchGroups">
                    <DropdownCustom
                        defaultItem={'Search by Quote Ref'}
                        dataItem={listSearch}
                        ondataChange={handleDataChange}
                    />
                    <div>
                        <Input
                            size="large"
                            placeholder={
                                selectedValue === 'Quote Ref' ||
                                selectedValue === null
                                    ? 'Search'
                                    : 'Please enter your client name'
                            }
                            value={searchValue}
                            onChange={handleInputChange}
                            prefix={
                                <img
                                    alt=""
                                    className="iconfile"
                                    src={icons.searchIcon}
                                />
                            }
                        />
                    </div>
                    {debouncedValue && (
                        <div className="totalResponse">
                            {dataList.length} results found.
                        </div>
                    )}
                </div>
                <div className="viewSort">
                    <span className="titleSort">Sort by</span>
                    <DropdownCustom
                        defaultItem={'Updated date'}
                        dataItem={listSort}
                        ondataChange={handleDataSort}
                    />
                </div>
            </div>
            {dataList.length > 0 ? (
                <div>
                    <Table
                        dataSource={dataList}
                        columns={columns}
                        pagination={{ pageSize: page }}
                        rowClassName={(record, index) =>
                            index % 2 === 0 ? 'highlight-row' : 'custom-row'
                        }
                        onRow={(record) => {
                            return {
                                onClick: () => handleRowClick(record), // Click event for the row
                            };
                        }}
                    />
                    <div className="viewNumPage">
                        <span>Items per page</span>
                        <DropdownCustom
                            defaultItem={page}
                            dataItem={showColumOnPage}
                            isNotPagination={false}
                            ondataChange={handleChangePagination}
                        />
                    </div>
                    <ModalComponent
                        width={1201}
                        visible={isModalVisible}
                        onClose={handleClose}
                        title="Quote ID UK050757 - Previous versions"
                        content={<VersionModal />}
                    />
                </div>
            ) : (
                <NotFound
                    content={
                        <div>
                            {dataList.length > 0 ? (
                                <div>
                                    <div>
                                        Hmm... Sorry we couldn’t find any
                                        matches for “Abc”.
                                    </div>
                                    <div>Clear search & Show all results</div>
                                </div>
                            ) : (
                                <div>
                                    We're sorry, but there are no items to
                                    display at this time. Please check back
                                    later
                                </div>
                            )}
                        </div>
                    }
                />
            )}
        </ListQuotesComponent>
    );
};

export default ViewTableComponent;
